import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import Home from "./components/homepage/home";
import Category from "./components/categorypage/category";
import NthIndex from "./components/BusinessDetailsPage/nthindex/nthindex";
import Aayilyam from "./components/BusinessDetailsPage/aayilyam/aayilyam";
import NthIndexFeatured from "./components/featuredbusinessdetails/nthindexfeatured/nthindex.jsx";
import AayilyamFeatured from "./components/featuredbusinessdetails/aayilyamfeatured/aayilyamfeatured.jsx";
import FabsomeGarments from "./components/featuredbusinessdetails/fabsomegarments/fabsomegarments.jsx";
import G6Instruments from "./components/featuredbusinessdetails/g6/g6.jsx";
import GreenField from "./components/HomeCategory/companydetails/greenfield/greenfield";
import NEIS from "./components/HomeCategory/companydetails/neis/neis";
import Professors from "./components/HomeCategory/companydetails/professorsacademy/professors";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category" element={<Category />} />
          <Route path="/nthindex" element={<NthIndex />} />
          <Route path="/aayilyam" element={<Aayilyam />} />
          <Route path="/nthindexfeatured" element={<NthIndexFeatured />} />
          <Route path="/aayilyamfeatured" element={<AayilyamFeatured />} />
          <Route path="/fabsomegarments" element={<FabsomeGarments />} />
          <Route path="/g6" element={<G6Instruments />} />
          <Route path="/greenfield" element={<GreenField />} />
          <Route path="/neis" element={<NEIS />} />
          <Route path="/professorsacademy" element={<Professors />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
