import React from "react";
import Banner from "../banner/banner";
import FeaturedBusiness from "../featuredBusiness/featured";
import Header from "../header/header";
import Meeting from "../meetingHall/meeting";
import Restaurants from "../restaurent/restaurent";
import Footer from "../footer/footer";
import PremiumBusiness from "../premium/premium";
import BusinessType from "../businesstype/business";
import "./home.css";
import ComingSoon from "../comingsoon/comingsoon";
import HomeCategory from "../HomeCategory/HomeCategory";


function Home() {
  return (
    <section id="home">
      <div>
        <Header />
        <div className="bg">
        <Banner />
        <BusinessType />
        <PremiumBusiness />
        </div>
        <FeaturedBusiness />
        <HomeCategory />
        <ComingSoon />
        <Meeting />
        <Restaurants />
        <Footer />
      </div>
    </section>
  );
}

export default Home;
