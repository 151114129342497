import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./greenfield.css";
import greenfield from "../../../../assets/homecategory/education/greenfield.jpeg";
import category from "../../../../assets/images/category.svg";
import location from "../../../../assets/images/location.svg";
import email from "../../../../assets/images/email.svg";
import web from "../../../../assets/images/web.svg";
import android from "../../../../assets/images/android.svg";
import Button from "react-bootstrap/Button";
import fb from "../../../../assets/images/fb.svg";
import insta from "../../../../assets/images/insta.svg";
import yu from "../../../../assets/images/yu.svg";
import wa from "../../../../assets/images/wa.svg";
import Header from "../../../header/header";
import Banner from "../../../banner/banner";
import Footer from "../../../footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import call from "../../../../assets/images/call.svg";
import map from "../../../../assets/images/map.svg";
import { useNavigate } from "react-router-dom";

function GreenField() {

  return (
    <section id="details">
      <Header />
      <div className="bg3">
        <Banner />

        <Container className="text-start">
          <Row className="details-row">
            <Col lg={4} className="nthindex-col">
              <img src={greenfield} className="img-fluid" />
            </Col>

            <Col lg={8} className="company-details">
              <div className="company-name mb-4">Green Field Academy</div>

              <div className="company-category">
                <img src={category} className="me-2" />
                <span>Educational Organization</span>
              </div>
              <div className="company-frame">
                <div className="company-address">
                  <img src={location} className="me-2" />
                  <div className="text-start">
                    <span>Thrissur, Kerala</span>
                    <div>
                      West fort plaza-3, Kanjani Road, West Fort Jn, Thrissur,
                      Kerala 680004
                    </div>
                  </div>
                </div>

                <div className="company-email">
                  <img src={email} className="me-2" />
                  <a href="mailto://greenfieldacademy@gmail.com">
                    greenfieldacademy@gmail.com
                  </a>
                </div>

                <div className="company-web">
                  <img src={web} className="me-2" />
                  <a href="">________</a>
                </div>

                <div className="company-call">
                  <img src={android} className="me-2" />
                  <a href="tel://+917874138389">+91 7874138389, </a>{" "}
                  <a href="tel://+917034438389" className="ms-2">
                    {" "}
                    +91 7034438389
                  </a>
                </div>
                <div className="contact-buttons">
                  <a href="https://wa.me/7034438389" className="contact pe-4">
                    <Button className="contact-wu">
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{ color: "#ffffff", height: "25px" }}
                      />
                      &nbsp;&nbsp;Chat on Whatsapp
                    </Button>
                  </a>
                  <a
                    href="tel:+917874138389"
                    class="float1 pe-4"
                    target="_blank"
                  >
                    <Button className="contact-call">
                      <img src={call} />
                      &nbsp;&nbsp;Call
                    </Button>
                  </a>
                  <a
                    href="https://goo.gl/maps/18uaxrFEynT86uBP9"
                    class="float1"
                    target="_blank"
                  >
                    <Button className="contact-map">
                      <img src={map} />
                      &nbsp;&nbsp;Open In Map
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <div className="company-description">
            Green Field Academy is the institute that provides author trainers
            for both online and classroom training for OET. Our trainers
            understand the requirements and nuances of the test, and thus are
            able to assist each trainee in understanding the test better and
            help each candidate score better.
          </div>
          <div className="company-service-title">Services</div>
          <div className="service-list">
            <ul>
              <li>OET Course</li>
              <li>OSCE Course</li>
              <li>CBT Course</li>
            </ul>
          </div>
          <div className="company-social-div">
            <div className="company-social">
              Follow Us On:
              <a href="#" className="footer-item">
                <img src={fb}></img>
              </a>
              <a href="#" className="footer-item">
                <img src={insta}></img>
              </a>
              <a href="#" className="footer-item">
                <img src={yu}></img>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </section>
  );
}

export default GreenField;
