import { Container, Col, Row } from "react-bootstrap";
import "./g6.css";
import g6 from "../../../assets/featured/g6.jpg";
import category from "../../../assets/images/category.svg";
import location from "../../../assets/images/location.svg";
import email from "../../../assets/images/email.svg";
import web from "../../../assets/images/web.svg";
import android from "../../../assets/images/android.svg";
import Button from "react-bootstrap/Button";
import fb from "../../../assets/images/fb.svg";
import insta from "../../../assets/images/insta.svg";
import yu from "../../../assets/images/yu.svg";
import wa from "../../../assets/images/wa.svg";
import Header from "../../header/header";
import Banner from "../../banner/banner";
import Footer from "../../footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import call from "../../../assets/images/call.svg";
import map from "../../../assets/images/map.svg";

function G6Instruments() {
  return (
    <section id="details">
      <Header />
      <div className="bg3">
        <Banner />

        <Container className="text-start">
          <Row className="details-row">
            <Col lg={4} className="nthindex-col">
              <img src={g6} className="img-fluid" />
            </Col>

            <Col lg={8} className="company-details">
              <div className="company-name mb-4">G6 Jewellery Instruments</div>

              <div className="company-category">
                <img src={category} className="me-2" />
                <span>Jewellery Instruments Industry</span>
              </div>
              <div className="company-frame">
                <div className="company-address">
                  <img src={location} className="me-2" />
                  <div className="text-start">
                    <span>Thrissur, Kerala</span>
                    <div>
                      Ground Floor, Leshore Business Park, Behind Railway
                      Station, Poothole, Thoppinmoola, Thrissur - 4
                    </div>
                  </div>
                </div>

                <div className="company-email">
                  <img src={email} className="me-2" />
                  <a href="mailto:gsixinstruments@gmail.com">
                    gsixinstruments@gmail.com
                  </a>
                </div>

                <div className="company-web">
                  <img src={web} className="me-2" />
                  <a href="https://www.g6instruments.com/">
                    https://www.g6instruments.com/
                  </a>
                </div>

                <div className="company-call">
                  <img src={android} className="me-2" />
                  <a href="tel://+919778467184">+919778467184, </a>
                  <a href="tel://+919778467190" className="ms-2">
                    +919778467190{" "}
                  </a>
                </div>
                <div className="contact-buttons">
                  <a
                    href="https://wa.me/+919778467184"
                    className="contact pe-4"
                  >
                    <Button className="contact-wu">
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{ color: "#ffffff", height: "25px" }}
                      />
                      &nbsp;&nbsp;Chat on Whatsapp
                    </Button>
                  </a>
                  <a
                    href="tel:+919778467190"
                    class="float1 pe-4"
                    target="_blank"
                  >
                    <Button className="contact-call">
                      <img src={call} />
                      &nbsp;&nbsp;Call
                    </Button>
                  </a>
                  <a
                    href="https://goo.gl/maps/QTPzYreSqLqawt1E9"
                    class="float1"
                    target="_blank"
                  >
                    <Button className="contact-map">
                      <img src={map} />
                      &nbsp;&nbsp;Open In Map
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <div className="company-description">
            We G6 jewellery instruments one of the leading marketing company in
            jewellery instruments industry in South India. We have a
            professionally trained service-oriented team and well experienced
            marketing personalities to drive the system effectively. We are
            strong enough to meet the requirements coming from corporate
            jewellery outlets, retail jewellery showrooms, jewellery
            manufacturing factories, diamond manufacturing establishment,assay
            and hallmarking centres, gold refining units, banks and NBFC'S. We
            have never compromise on the quality of products and services
            provided to the customers. Our company believe in keeping the
            customer happy and providing them the products at very competent
            price.
          </div>
          {/* <div className="company-service-title">Services</div>
          <div className="service-list">
            <ul>
              <li>REPEATERS BATCH Course</li>
              <li>+1, +2 ENTRANCE ONLY BATCH Course</li>
              <li>+1, +2 TUITION PLUS ENTRANCE BATCH Course</li>
              <li>CRASH COURSE </li>
              <li>BRIDGE COURSE</li>
            </ul>
          </div> */}
          <div className="company-social-div">
            <div className="company-social">
              Follow Us On:
              <a
                href="https://www.facebook.com/G6-Jewellery-Instruments-101662095652771/"
                className="footer-item"
              >
                <img src={fb}></img>
              </a>
              <a
                href="https://instagram.com/g6instruments/"
                className="footer-item"
              >
                <img src={insta}></img>
              </a>
              <a href="#" className="footer-item">
                <img src={yu}></img>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </section>
  );
}

export default G6Instruments;
