import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import meeting from "../../assets/meetingHall/meetinghall.png";
import "./meeting.css";

function Meeting() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <section id="meeting">
      <div>
        <div className="meeting-head">Meeting Hall</div>
      </div>
      <Carousel responsive={responsive} loop wrap={true} ride={true}>
        <div className="cr">
          <img
            src={require("../../assets/meetingHall/meetinghall.png")}
            alt="meetinghall"
            className="meetinghall-img" 
          />
          <p>De Alfresco Restaurant</p>
        </div>

        <div className="cr">
          <img
            src={require("../../assets/meetingHall/meetinghall.png")}
            alt="meetinghall"
            className="meetinghall-img" 
          />
          <p>De Alfresco Restaurant</p>
        </div>

        <div className="cr">
          <img
            src={require("../../assets/meetingHall/meetinghall.png")}
            alt="meetinghall"
            className="meetinghall-img" 
          />
          <p>De Alfresco Restaurant</p>
        </div>

        <div className="cr">
          <img
            src={require("../../assets/meetingHall/meetinghall.png")}
            alt="meetinghall"
            className="meetinghall-img" 
          />
          <p>De Alfresco Restaurant</p>
        </div>

        <div className="cr">
          <img
            src={require("../../assets/meetingHall/meetinghall.png")}
            alt="meetinghall"
            className="meetinghall-img" 
          />
          <p>De Alfresco Restaurant</p>
        </div>

        <div className="cr">
          <img
            src={require("../../assets/meetingHall/meetinghall.png")}
            alt="meetinghall"
            className="meetinghall-img" 
          />
          <p>De Alfresco Restaurant</p>
        </div>
      </Carousel>
    </section>
  );
}

export default Meeting;
