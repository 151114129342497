import { Container, Col, Row } from "react-bootstrap";
import "./footer.css";
import fb from "../../assets/images/fb.svg";
import insta from "../../assets/images/insta.svg";
import wa from "../../assets/images/wa.svg";
import yu from "../../assets/images/yu.svg";
import logoWhite from "../../assets/images/logo-white.svg";

function Footer() {
  return (
    <section id="footer">
    <div>
      <div className="footer">
        <Container>
          <Row className="align-items-center">
            <Col lg={3}>
              <img
                src={logoWhite}
                alt="Mallubusiness Logo"
                className="footer-header img-fluid"
              ></img>
              <div className="mb-5">
                T&J Tower, Near JMC Bus Stop Aranattukkara, PIN 680618,
                Thrissur, Kerala
              </div>
            </Col>

            <Col lg={3}>
              <div className="footer-header">Navigations</div>
              <div>
                <a href="" className="footer-item">
                  Home
                </a>
              </div>
              <div>
                <a href="" className="footer-item">
                  Category
                </a>
              </div>

              <div>
                <a href="" className="footer-item">
                  About Us
                </a>
              </div>

              <div className="mb-5">
                <a href="" className="footer-item">
                  Contact Us
                </a>
              </div>
            </Col>

            <Col lg={3}>
              <div className="footer-header">Contact Us</div>
              <div>
                <a
                  href="mailto://info@mallubusiness.com"
                  className="footer-item"
                >
                  info@mallubusiness.com
                </a>
              </div>

              <div className="mb-5">
                <a href="tel://+919048445114" className="footer-item">
                  +91 9048 445114
                </a>
              </div>
            </Col>

            <Col lg={3}>
              <div className="footer-header">Follow Us On</div>
              <div className="footer-social">
                <a
                  href="https://www.facebook.com/mallubusinesskerala/"
                  className="footer-item"
                >
                  <img src={fb}></img>
                </a>
                <a
                  href="https://www.instagram.com/mallubusinesskerala/"
                  className="footer-item"
                >
                  <img src={insta}></img>
                </a>
                <a href="https://wa.me/919048445114" className="footer-item">
                  <img src={wa}></img>
                </a>
                <a href="#" className="footer-item">
                  <img src={yu}></img>
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="copyright">
        © Copyright 2023 All Rights Reserved
      </div>
    </div>
    </section>
  );
}

export default Footer;
