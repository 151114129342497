import { Container, Col, Row } from "react-bootstrap";
import "./fabsomegarments.css";
import fabsome from "../../../assets/featured/fabsomegarments.jpeg";
import category from "../../../assets/images/category.svg";
import location from "../../../assets/images/location.svg";
import email from "../../../assets/images/email.svg";
import web from "../../../assets/images/web.svg";
import android from "../../../assets/images/android.svg";
import Button from "react-bootstrap/Button";
import fb from "../../../assets/images/fb.svg";
import insta from "../../../assets/images/insta.svg";
import yu from "../../../assets/images/yu.svg";
import wa from "../../../assets/images/wa.svg";
import Header from "../../header/header";
import Banner from "../../banner/banner";
import Footer from "../../footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import call from "../../../assets/images/call.svg";
import map from "../../../assets/images/map.svg";

function FabsomeGarments() {
  return (
    <section id="details">
      <Header />
      <div className="bg3">
        <Banner />

        <Container className="text-start">
          <Row className="details-row">
            <Col lg={4} className="nthindex-col">
              <img src={fabsome} className="img-fluid" />
            </Col>

            <Col lg={8} className="company-details">
              <div className="company-name mb-4">FABSOME GARMENTS</div>

              <div className="company-category">
                <img src={category} className="me-2" />
                <span>Manufactures of Readymade Garments</span>
              </div>
              <div className="company-frame">
                <div className="company-address">
                  <img src={location} className="me-2" />
                  <div className="text-start">
                    <span>Tiruppur, Tamilnadu</span>
                    <div>
                      Door No.75, Samundipuram, 2nd Street, Gandhi Nagar,
                      Tiruppur, Tamilnadu, 641603, GST No.33BBAPV9497A1ZF
                    </div>
                  </div>
                </div>

                <div className="company-email">
                  <img src={email} className="me-2" />
                  <a href="mailto:venu@fabsomegarments.com">
                    venu@fabsomegarments.com
                  </a>
                  <img src={email} className="me-2 ms-2" />
                  <a href="mailto:akshay@fabsomegarments.com">
                    akshay@fabsomegarments.com
                  </a>
                </div>

                <div className="company-web">
                  <img src={web} className="me-2" />
                  <a href="#">https://www.fabsomegarments.com</a>
                </div>

                <div className="company-call">
                  <img src={android} className="me-2" />
                  <a href="tel:+916238645685">+916238645685, </a>
                  <a href="tel:+918086247770" className="ms-2">
                    +918086247770
                  </a>
                </div>
                <div className="contact-buttons">
                  <a href="https://wa.me/8086247770" className="contact pe-4">
                    <Button className="contact-wu">
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{ color: "#ffffff", height: "25px" }}
                      />
                      &nbsp;&nbsp;Chat on Whatsapp
                    </Button>
                  </a>
                  <a
                    href="tel:+916238645685"
                    class="float1 pe-4"
                    target="_blank"
                  >
                    <Button className="contact-call">
                      <img src={call} />
                      &nbsp;&nbsp;Call
                    </Button>
                  </a>
                  <a
                    href="https://www.google.com/maps/@10.6919558,76.4215983,15z"
                    class="float1"
                    target="_blank"
                  >
                    <Button className="contact-map">
                      <img src={map} />
                      &nbsp;&nbsp;Open In Map
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <div className="company-description">
            Our mission is to supply to our customers "full package" program of
            knitted apparel products of the highest level of
            quality,service,compliance and value
          </div>
          <div className="company-service-title">Services</div>
          <div className="service-list">
            <ul>
              <li>Women's wear</li>
              <li>Men's wear</li>
              <li>Kid's wear(Unisex)</li>
              
            </ul>
          </div>
          <div className="company-social-div">
            <div className="company-social">
              Follow Us On:
              <a href="https://www.facebook.com/" className="footer-item">
                <img src={fb}></img>
              </a>
              <a href="https://www.instagram.com/" className="footer-item">
                <img src={insta}></img>
              </a>
              <a href="#" className="footer-item">
                <img src={yu}></img>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </section>
  );
}

export default FabsomeGarments;
