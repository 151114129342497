import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import restaurent from "../../assets/restaurents/meetinghall.png";
import "./restaurent.css";

function Restaurants() {


  const navigate = useNavigate();

  function Categorypage(){
    navigate("/category");
    window.scrollTo(0, 0);
  }

  return (
    <section id="restaurants">
      <div>
        <Container>
          <div className="restaurant-head">Restaurants</div>
          <div className="gradient">
          <Row>
            <Col lg={4} className="res-col pb-5">
              <div className="res-div">
                <img
                  src={require("../../assets/restaurents/meetinghall.png")}
                  alt="restaurents"
                />
                <p>De Alfresco Restaurant</p>
              </div>
            </Col>
            <Col lg={4} className="res-col pb-5">
              <div className="res-div">
                <img
                  src={require("../../assets/restaurents/meetinghall.png")}
                  alt="restaurents"
                />
                <p>De Alfresco Restaurant</p>
              </div>
            </Col>
            <Col lg={4} className="res-col pb-5">
              <div className="res-div">
                <img
                  src={require("../../assets/restaurents/meetinghall.png")}
                  alt="restaurents"
                />
                <p>De Alfresco Restaurant</p>
              </div>
            </Col>
            <Col lg={4} className="res-col pb-5">
              <div className="res-div">
                <img
                  src={require("../../assets/restaurents/meetinghall.png")}
                  alt="restaurents"
                />
                <p>De Alfresco Restaurant</p>
              </div>
            </Col>
            <Col lg={4} className="res-col pb-5">
              <div className="res-div">
                <img
                  src={require("../../assets/restaurents/meetinghall.png")}
                  alt="restaurents"
                />
                <p>De Alfresco Restaurant</p>
              </div>
            </Col>
            <Col lg={4} className="res-col pb-5">
              <div className="res-div">
                <img
                  src={require("../../assets/restaurents/meetinghall.png")}
                  alt="restaurents"
                />
                <p>De Alfresco Restaurant</p>
              </div>
            </Col>
          </Row>
          </div>
          <div className="more-mallubtn">
            <Button  className="more-mallu" onClick={Categorypage}>
              Click for More Mallu Business<span>&nbsp;&gt;&gt;</span>
            </Button>
          </div>
        </Container>
      </div>
    </section>
  );
}

export default Restaurants;
