import Container from "react-bootstrap/Container";
import React from "react";
import "./banner.css";
import { Row, Col } from "react-bootstrap";
import banner from "../../assets/images/banner.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import shop from "../../assets/images/shop.svg";
import Button from "react-bootstrap/Button";


// fa-location-dot
//dropdown

import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import BusinessType from "../businesstype/business";
import PremiumBusiness from "../premium/premium";

const locationOptions = ["Enter Your Location", "one", "two", "three"];
const categoryOptions = ["Select Category", "one", "two", "three"];

function Banner() {
  const defaultLocationOption = locationOptions[0];
  const defaultCategoryOption = categoryOptions[0];

  return (
    <section id="banner">
     
        <Container>
          <div className="bannerContainer">
            <img src={banner} className="img-fluid banner" />
            <div></div>
            <div className="tagline">
              Whatever your <span className="ps-2">Business</span> is,
              <br />
              Get <span className="ps-2">Listed</span> & Get{" "}
              <span className="ps-2">Boosted</span>
            </div>
            <div className="searchBar d-none d-lg-flex">
              <FontAwesomeIcon
                icon={faLocationDot}
                style={{ color: "#797979" }}
              />
              <Dropdown
                options={locationOptions}
                value={defaultLocationOption}
                placeholder="Select an option"
              />
              <div className="partition"></div>
              <img src={shop} />
              <Dropdown
                options={categoryOptions}
                value={defaultCategoryOption}
                placeholder="Select an option"
              />

              <div className="partition"></div>
              <Button  className="ms-3 search-btn">
                Search
              </Button>
            </div>
          </div>
        </Container>
     
    </section>
  );
}

export default Banner;
