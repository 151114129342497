import Container from 'react-bootstrap/Container';
import React, { useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import brand_logo from '../../assets/images/logo.svg';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons' 
import { faPhone } from '@fortawesome/free-solid-svg-icons' 
import Button from 'react-bootstrap/Button';
import './header.css';
import { useLocation } from 'react-router-dom';
import jQuery, { nodeName } from "jquery";

function Header() {
  const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 100);
    }
  }, [pathname, hash, key]);

    return (
      <Navbar bg="#00ffffff" expand="lg">
      <Container>
        <Navbar.Brand href="/#home"><img src={brand_logo}></img></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/#home">Home</Nav.Link>
            <Nav.Link href="/category">Category</Nav.Link>
            <Nav.Link href="#">About Us</Nav.Link>
            <Nav.Link href="/#footer">Contact Us</Nav.Link>
          </Nav>
         
          <Nav className="ms-auto navlinks">
            <Nav.Link href="tel://+919048445114"><FontAwesomeIcon icon={faPhone} style={{color: "#797979",}}/></Nav.Link>
            <Nav.Link href="tel://+919048445114"> +91 9048 445114</Nav.Link>
            <Nav.Link href="#seperator">|</Nav.Link>
            <Nav.Link href="https://www.facebook.com/mallubusinesskerala/"><FontAwesomeIcon icon={faFacebook} style={{color: "#797979",}} /></Nav.Link>
            <Nav.Link href="https://www.instagram.com/mallubusinesskerala/"><FontAwesomeIcon icon={faInstagram} style={{color: "#797979",}} /></Nav.Link>
            <Nav.Link href="#category"><FontAwesomeIcon icon={faYoutube} style={{color: "#797979",}} /></Nav.Link>
            <Button variant="outline-dark" className="ms-3 login-btn">Login</Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    );
  }


  export default Header;