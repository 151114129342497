import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import greenfield from "../../../assets/homecategory/education/greenfield.jpeg";
import neis from "../../../assets/homecategory/education/neis-1.jpeg";
import professors from "../../../assets/homecategory/education/prof.jpeg";
import "./Education.css";

function Education() {
  const navigate = useNavigate();

  function greenfieldacademy() {
    navigate("/greenfield");
    window.scrollTo(0, 0);
  }
  function Neis() {
    navigate("/neis");
    window.scrollTo(0, 0);
  }
  function professorsacademy() {
    navigate("/professorsacademy");
    window.scrollTo(0, 0);
  }
  return (
    <section id="education">
      <Container>
        <div>
          <div className="education-heading">Education</div>
          <Row>
            <Col lg={4} className="education-col pb-4">
              <div className="education-div" onClick={greenfieldacademy}>
                <img src={greenfield} alt="" />
                <div className="company-names pt-3 ps-3">
                  Green Field Academy
                </div>
                <div className="company-service">
                  <div className="service ps-3">OET Course</div>
                  <div className="service ps-3">OSCE Course</div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="featured-col pb-4">
              <div className="featured-div" onClick={Neis}>
                <img src={neis} alt="" />
                <div className="company-names pt-3 ps-3">
                  Nuclear Engineering Inspection Serivce
                </div>
                <div className="company-service">
                  <div className="service ps-3">QA / QC Mechanical Course</div>
                  <div className="service ps-3">
                    QA / QC With Advanced NDT Course
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="featured-col pb-4">
              <div className="featured-div" onClick={professorsacademy}>
                <img src={professors} alt="" />
                <div className="company-names pt-3 ps-3">
                  Professors Academy
                </div>
                <div className="company-service">
                  <div className="service ps-3">Bridge Course</div>
                  <div className="service ps-3">Crash Course</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default Education;
