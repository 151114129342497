import { Container, Col, Row } from "react-bootstrap";
import "./professors.css";
import professorsacademy from "../../../../assets/homecategory/education/prof.jpeg";
import category from "../../../../assets/images/category.svg";
import location from "../../../../assets/images/location.svg";
import email from "../../../../assets/images/email.svg";
import web from "../../../../assets/images/web.svg";
import android from "../../../../assets/images/android.svg";
import Button from "react-bootstrap/Button";
import fb from "../../../../assets/images/fb.svg";
import insta from "../../../../assets/images/insta.svg";
import yu from "../../../../assets/images/yu.svg";
import wa from "../../../../assets/images/wa.svg";
import Header from "../../../header/header";
import Banner from "../../../banner/banner";
import Footer from "../../../footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import call from "../../../../assets/images/call.svg";
import map from "../../../../assets/images/map.svg";

function Professors() {
  return (
    <section id="details">
      <Header />
      <div className="bg3">
        <Banner />

        <Container className="text-start">
          <Row className="details-row">
            <Col lg={4} className="nthindex-col">
              <img src={professorsacademy} className="img-fluid" />
            </Col>

            <Col lg={8} className="company-details">
              <div className="company-name mb-4">Professors Academy</div>

              <div className="company-category">
                <img src={category} className="me-2" />
                <span>Educational Organization</span>
              </div>
              <div className="company-frame">
                <div className="company-address">
                  <img src={location} className="me-2" />
                  <div className="text-start">
                    <span>Thrissur, Kerala</span>
                    <div>THRISSUR City Centre, Naikkanal, 680001</div>
                  </div>
                </div>

                <div className="company-email">
                  <img src={email} className="me-2" />
                  <a href="mailto:academichead@professorsacademy.org">
                    academichead@professorsacademy.org
                  </a>
                </div>

                <div className="company-web">
                  <img src={web} className="me-2" />
                  <a href="https://www.professorsacademy.org/">
                    www.professorsacademy.org/
                  </a>
                </div>

                <div className="company-call">
                  <img src={android} className="me-2" />
                  <a href="tel://+919037187800">+919037187800 </a>
                </div>
                <div className="contact-buttons">
                  <a href="https://wa.me/9037187800" className="contact pe-4">
                    <Button className="contact-wu">
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{ color: "#ffffff", height: "25px" }}
                      />
                      &nbsp;&nbsp;Chat on Whatsapp
                    </Button>
                  </a>
                  <a
                    href="tel:+919037187800"
                    class="float1 pe-4"
                    target="_blank"
                  >
                    <Button className="contact-call">
                      <img src={call} />
                      &nbsp;&nbsp;Call
                    </Button>
                  </a>
                  <a
                    href="https://goo.gl/maps/7L4xMsdrFPrfN3Tf7"
                    class="float1"
                    target="_blank"
                  >
                    <Button className="contact-map">
                      <img src={map} />
                      &nbsp;&nbsp;Open In Map
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <div className="company-description">
            Established in 2012, Professors Academy aims at providing the best
            education for students of all sections of society. Within a few
            years, along the academic excellence our institute achieved its
            fame. Our institute incorporated to impart quality education to
            students preparing for various exams with sky-high ideas and
            commitment to excellence. We are really proud that since the
            beginning itself, institute have enhanced outcome-oriented service
            for students irrespective of their economic standards. Financial
            incapability is a major hindrance for students to get access in
            reputed coaching institutes. The highly competitive exams need
            proper guidance from the beginning itself. Those skilled and
            talented students often left their dreams of medical/engineering
            fields because of economic backwardness. We the professor's academy
            is highly promoting passioned students especially of economically
            backward sections. We are grateful that our team work resulted in
            dream accomplishment of thousands of students with in these few
            years. It is to be acknowledged that the efficient teamwork of
            teaching and non-teaching staffs resulted in high success for
            academy along with the students. Efficient teaching methods and
            exam-oriented study materials enhanced the success of our institute.
          </div>
          <div className="company-service-title">Services</div>
          <div className="service-list">
            <ul>
              <li>REPEATERS BATCH Course</li>
              <li>+1, +2 ENTRANCE ONLY BATCH Course</li>
              <li>+1, +2 TUITION PLUS ENTRANCE BATCH Course</li>
              <li>CRASH COURSE </li>
              <li>BRIDGE COURSE</li>
            </ul>
          </div>
          <div className="company-social-div">
            <div className="company-social">
              Follow Us On:
              <a
                href="https://www.facebook.com/ProfessorsAcademyKerala"
                className="footer-item"
              >
                <img src={fb}></img>
              </a>
              <a
                href="https://www.instagram.com/professors_academy_/?hl=en"
                className="footer-item"
              >
                <img src={insta}></img>
              </a>
              <a href="#" className="footer-item">
                <img src={yu}></img>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </section>
  );
}

export default Professors;
