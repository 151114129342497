import { Container, Col, Row } from "react-bootstrap";
import "./aayilyamfeatured.css";
import aayilyam from "../../../assets/premium/premium2.png";
import category from "../../../assets/images/category.svg";
import location from "../../../assets/images/location.svg";
import email from "../../../assets/images/email.svg";
import web from "../../../assets/images/web.svg";
import android from "../../../assets/images/android.svg";
import Button from "react-bootstrap/Button";
import fb from "../../../assets/images/fb.svg";
import insta from "../../../assets/images/insta.svg";
import yu from "../../../assets/images/yu.svg";
import wa from "../../../assets/images/wa.svg";
import Header from "../../header/header";
import Banner from "../../banner/banner";
import Footer from "../../footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import call from "../../../assets/images/call.svg";
import map from "../../../assets/images/map.svg";

function AayilyamFeatured() {
  return (
    <section id="details">
      <Header />
      <div className="bg3">
        <Banner />

        <Container className="text-start">
          <Row className="details-row">
            <Col lg={4} className="nthindex-col">
              <img src={aayilyam} className="img-fluid" />
            </Col>

            <Col lg={8} className="company-details">
              <div className="company-name mb-4">Aayillyam Stores</div>

              <div className="company-category">
                <img src={category} className="me-2" />
                <span>Stationary Shop</span>
              </div>
              <div className="company-frame">
                <div className="company-address">
                  <img src={location} className="me-2" />
                  <div className="text-start">
                    <span>Thrissur, Kerala</span>
                    <div>Pullazhi, 680012</div>
                  </div>
                </div>

                <div className="company-email">
                  <img src={email} className="me-2" />
                  <a href="#">aayilyamstores@gmail.com</a>
                </div>

                <div className="company-web">
                  <img src={web} className="me-2" />
                  <a href="#">https://www.aayilliyamstores.org/</a>
                </div>

                <div className="company-call">
                  <img src={android} className="me-2" />
                  <a href="tel://+91 9745133405">+91 9745133405 </a>
                </div>
                <div className="contact-buttons">
                  <a href="https://wa.me/9745133405" className="contact pe-4">
                    <Button className="contact-wu">
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{ color: "#ffffff", height: "25px" }}
                      />
                      &nbsp;&nbsp;Chat on Whatsapp
                    </Button>
                  </a>
                  <a
                    href="tel:+919745133405"
                    class="float1 pe-4"
                    target="_blank"
                  >
                    <Button className="contact-call">
                      <img src={call} />
                      &nbsp;&nbsp;Call
                    </Button>
                  </a>
                  <a
                    href="https://goo.gl/maps/gz2Jue23R4KCBDkU6"
                    class="float1"
                    target="_blank"
                  >
                    <Button className="contact-map">
                      <img src={map} />
                      &nbsp;&nbsp;Open In Map
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          {/* <div className="company-description">
            Nthindex software solutions has the most passionate, enthusiastic
            and highly qualified developers handling the best technologies in
            the industry to design your dreams. We are here to provide mobile,
            web and desktop application with the primary focus being client
            satisfaction in all areas. Apart from software services, we can
            offer you comprehensive digital marketing and other design services.
          </div>
          <div className="company-service-title">Services</div>
          <div className="service-list">
            <ul>
              <li>Website Development</li>
              <li>Application Development</li>
              <li>Android Mobile Application Development</li>
              <li>iOS Mobile Application Development</li>
              <li>Computer Software Development</li>
            </ul>
          </div> */}
          <div className="company-social-div">
            <div className="company-social">
              Follow Us On:
              <a href="https://www.facebook.com/" className="footer-item">
                <img src={fb}></img>
              </a>
              <a href="https://www.instagram.com/" className="footer-item">
                <img src={insta}></img>
              </a>
              <a href="#" className="footer-item">
                <img src={yu}></img>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </section>
  );
}

export default AayilyamFeatured;
