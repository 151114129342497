import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Mobile from "../../assets/comingsoon/mobile.svg";
import "./comingsoon.css";

function ComingSoon() {
  return (
    <section id="comingsoon">
      <div className="comingsoon-bg">
        <Container>
          <Row className="comingsoon-row">
            <Col lg={4} className="mobile-col">
              <img src={Mobile} alt="mobile" />
            </Col>
            <Col lg={6} className="soon-col">
              <div className="comingsoon-head">
                <span className="sp1">Coming</span>
                <span className="sp2 ms-2">Soon...</span>
              </div>
              <div className="ios">
                We Are Launching our Mobile Apps in Android and iOS Platforms
              </div>
              <p>Get Notified When we Launch our Apps</p>
              <div className="contact">
                <input
                  id="email"
                  class="forms-id mt-2"
                  type="email"
                  name="email"
                  placeholder="Enter your email"
                  required=""
                />
                <div className="comingsoon-btn ps-4">
                  <Button type="button" className="comingsoon-button">Submit</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default ComingSoon;
