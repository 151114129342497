import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import hotel from "../../assets/category/hotel.svg";
import stationary from "../../assets/category/Shopping Bag.svg";
import dress from "../../assets/category/dress.svg";
import "./business.css";

function BusinessType() {
  return (
    <secction id="businesstype">
      <div>
        <Container>
          <div className="categories">
            <Row>
              <Col lg={3} xs={6}  className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={hotel} alt="hotel" />
                    <span className="ps-2">Restaurants</span>
                  </div>
                </div>
              </Col>
              <Col lg={3} xs={6}className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={stationary} alt="stationary" />
                    <span className="ps-2">Stationary Store</span>
                  </div>
                </div>
              </Col>
              <Col lg={3} xs={6} className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={dress} alt="dress" />
                    <span className="ps-2">Fashion Shop</span>
                  </div>
                </div>{" "}
              </Col>
              <Col lg={3} xs={6} className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={hotel} alt="hotel" />
                    <span className="ps-2">Restaurants</span>
                  </div>
                </div>
              </Col>
              <Col lg={3} xs={6} className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={stationary} alt="stationary" />
                    <span className="ps-2">Stationary Store</span>
                  </div>
                </div>
              </Col>
              <Col lg={3} xs={6} className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={dress} alt="dress" />
                    <span className="ps-2">Fashion Shop</span>
                  </div>
                </div>{" "}
              </Col>
              <Col lg={3} xs={6} className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={dress} alt="dress" />
                    <span className="ps-2">Fashion Shop</span>
                  </div>
                </div>{" "}
              </Col>
              <Col lg={3} xs={6} className="column">
                <div className="category-col">
                  <div className="category-data">
                    <img src={hotel} alt="hotel" />
                    <span className="ps-2">Restaurants</span>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </secction>
  );
}

export default BusinessType;
