import { Container, Col, Row } from "react-bootstrap";
import "./neis.css";
import neis from "../../../../assets/homecategory/education/neis-1.jpeg";
import category from "../../../../assets/images/category.svg";
import location from "../../../../assets/images/location.svg";
import email from "../../../../assets/images/email.svg";
import web from "../../../../assets/images/web.svg";
import android from "../../../../assets/images/android.svg";
import Button from "react-bootstrap/Button";
import fb from "../../../../assets/images/fb.svg";
import insta from "../../../../assets/images/insta.svg";
import yu from "../../../../assets/images/yu.svg";
import wa from "../../../../assets/images/wa.svg";
import Header from "../../../header/header";
import Banner from "../../../banner/banner";
import Footer from "../../../footer/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import call from "../../../../assets/images/call.svg";
import map from "../../../../assets/images/map.svg";

function NEIS() {
  return (
    <section id="details">
      <Header />
      <div className="bg3">
        <Banner />

        <Container className="text-start">
          <Row className="details-row">
            <Col lg={4} className=" neis">
              <img src={neis} className="img-fluid" />
            </Col>

            <Col lg={8} className="company-details">
              <div className="company-name mb-4">
                Nuclear Engineering Inspection Serivce
              </div>

              <div className="company-category">
                <img src={category} className="me-2" />
                <span>Educational Organization</span>
              </div>
              <div className="company-frame">
                <div className="company-address">
                  <img src={location} className="me-2" />
                  <div className="text-start">
                    <span>Thrissur, Kerala</span>
                    <div>
                      2nd floor, NP Complex, Chungam Kanjany Road, Near Nirmala
                      College Thrissur - 680 004 <br></br>Branches : Chennai, Mumbai, UAE
                    </div>
                  </div>
                </div>

                <div className="company-email">
                  <img src={email} className="me-2" />
                  <a href="mailto://info@nucleaengineering.in">
                    info@nucleaengineering.in
                  </a>
                </div>

                <div className="company-web">
                  <img src={web} className="me-2" />
                  <a href="https://nuclearengineering.in/">
                    nuclearengineering.in
                  </a>
                </div>

                <div className="company-call">
                  <img src={android} className="me-2" />
                  <a href="tel://+91 9207 305806">+91 9207 305806, </a>{" "}
                  <a href="tel://+91 7306 220800" className="ms-2">
                    {" "}
                    +91 7306 220800
                  </a>
                </div>
                <div className="contact-buttons">
                  <a href="https://wa.me/919048445114" className="contact pe-4">
                    <Button className="contact-wu">
                      <FontAwesomeIcon
                        icon={faWhatsapp}
                        style={{ color: "#ffffff", height: "25px" }}
                      />
                      &nbsp;&nbsp;Chat on Whatsapp
                    </Button>
                  </a>
                  <a
                    href="tel:+91 7306 220800"
                    class="float1 pe-4"
                    target="_blank"
                  >
                    <Button className="contact-call">
                      <img src={call} />
                      &nbsp;&nbsp;Call
                    </Button>
                  </a>
                  <a
                    href="https://goo.gl/maps/dahnnwVUPKZQQ5ZbA"
                    class="float1"
                    target="_blank"
                  >
                    <Button className="contact-map">
                      <img src={map} />
                      &nbsp;&nbsp;Open In Map
                    </Button>
                  </a>
                </div>
              </div>
            </Col>
          </Row>

          <div className="company-description">
            We are glad to introduce ourselves as a Manpower supplier in NDT,
            welding,Safety, Coating, API and many other positions related to the
            Oil & Gas sector. Since 2015 we started our services in Training &
            Manpower supply now we are well known for our manpower services in
            both India & abroad. We have provided Manpower in INDIA & ABROAD for
            few of our Major clients. Our goal is to provide high-quality
            professional inspection courses that will prepare students to be
            more "industry ready" rather than just academically qualified. The
            vertically skilled specialists develop and deliver all of our
            technical courses. Our placement cell has established relationships
            with some of the country's most prestigious employers. Almost all
            technical and non-technical industries are establishing their own
            quality control departments in order to save time and money. A
            fresher would have no clear understanding about the sectors and
            various specialisations available to them when looking for work.
            Each company has its own specialisations in order to reach its
            objectives as quickly as possible and with precision. We provide
            high-quality professional courses to assist new students in
            overcoming obstacles, while assisting the industrial sector in
            filling openings with qualified personnel.
          </div>
          <div className="company-service-title">Services</div>
          <div className="service-list">
            <ul>
            100% placement assistance for the following courses<br></br>
              <li>QA / QC Mechanical Course</li>
              <li>QA / QC With Advanced NDT Course</li>
              <li>QA /QC Oil And Gas Course</li>
              
            </ul>
          </div>
          <div className="company-social-div">
            <div className="company-social">
              Follow Us On:
              <a
                href="https://www.facebook.com/profile.php?id=100054340638207"
                className="footer-item"
              >
                <img src={fb}></img>
              </a>
              <a
                href="https://www.instagram.com/engineering_nuclear/"
                className="footer-item"
              >
                <img src={insta}></img>
              </a>
              <a href="https://youtu.be/qbl00YlXi78" className="footer-item">
                <img src={yu}></img>
              </a>
            </div>
          </div>
        </Container>
        <Footer />
      </div>
    </section>
  );
}

export default NEIS;
