import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import nthindex from "../../assets/featured/nthindex.png";
import aayilyam from "../../assets/featured/aayilyamstores.png";
import fabsomegarments from "../../assets/featured/fabsomegarments.jpeg";
import g from "../../assets/featured/g6.jpg";
import "./featured.css";

function FeaturedBusiness() {
  const navigate = useNavigate();

  function nthindexfeatured() {
    navigate("/nthindexfeatured");
    window.scrollTo(0, 0);
  }
  function aayilyamfeatured() {
    navigate("/aayilyamfeatured");
    window.scrollTo(0, 0);
  }
 
 
  function fabsome() {
    navigate("/fabsomegarments");
    window.scrollTo(0, 0);
  }
  function g6() {
    navigate("/g6");
    window.scrollTo(0, 0);
  }
  return (
    <section id="featured">
      <div>
        <Container>
          <div className="featured-head">Our Featured Business</div>

          <Row>
            <Col lg={4} className="featured-col pb-4">
              <div className="featured-div" onClick={nthindexfeatured}>
                <img src={nthindex} alt="" />
                <div className="company-names pt-3 ps-3">
                  Nthindex Software Solutions LLP
                </div>
                <div className="company-service">
                  <div className="service ps-3">Mobile App Development</div>
                  <div className="service ps-3">Webapp App Development ...</div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="featured-col pb-4">
              <div className="featured-div aayilliyam" onClick={aayilyamfeatured}>
                <img src={aayilyam} alt="" />
                <div className="company-names pt-3 ps-3">
                  Aayilyam Stores
                </div>
                <div className="company-service">
                  <div className="service ps-3">Stationary Items</div>
                  <div className="service ps-3">Vegetables</div>
                </div>
              </div>
            </Col>
          
            <Col lg={4} className="featured-col pb-4">
              <div className="featured-div" onClick={fabsome}>
                <img src={fabsomegarments} alt="" />
                <div className="company-names pt-3 ps-3">
                Fabsome Garments
                </div>
                <div className="company-service">
                  <div className="service ps-3">Men's wear</div>
                  <div className="service ps-3">Women's wear</div>
                </div>
              </div>
            </Col>
            <Col lg={4} className="featured-col pb-4">
              <div className="featured-div g6" onClick={g6}>
                <img src={g} alt="" />
                <div className="company-names pt-3 ps-3">
                G6 Jewellery Instruments
                </div>
                <div className="company-service">
                  <div className="service ps-3">Jewellery Instruments Industry</div>
                  <div className="service ps-3">Service-oriented team</div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
}

export default FeaturedBusiness;
