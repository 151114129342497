import React from "react";
import Education from "./Education/Education";
import "./HomeCategory.css";

function HomeCategory() {
  return (
    <section id="homecategory">
      <div>
        <Education />
      </div>
    </section>
  );
}

export default HomeCategory;
