import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import premium1 from "../../assets/premium/premium1.png";
import premium2 from "../../assets/premium/premium2.png";
import "./premium.css";

function PremiumBusiness() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const navigate = useNavigate();

  function nthindex() {
    navigate("/nthindex");
    window.scrollTo(0, 0);
  }
  function aayilyam() {
    navigate("/aayilyam");
    window.scrollTo(0, 0);
  }

  return (
    <section id="premium">
      <div>
        <div className="premium-section">
          <div className="premium-head">Our Premium Business</div>
        </div>

        <Carousel responsive={responsive} loop={true}>
          <img
            src={premium1}
            alt="premium"
            className="premium-img"
            onClick={nthindex}
          />

          <div className="premium-store">
            <img
              src={require("../../assets/premium/premium2.png")}
              alt="premium"
              className="premium-img"
              onClick={aayilyam}
            />
            <p>Aayillyam Stores</p>
          </div>

          <img src={premium1} alt="premium" className="premium-img" />

          <img src={premium2} alt="premium" className="premium-img" />

          <img src={premium1} alt="premium" className="premium-img" />

          <img src={premium2} alt="premium" className="premium-img" />

          <img src={premium2} alt="premium" className="premium-img" />

          <img src={premium1} alt="premium" className="premium-img" />

          <img src={premium2} alt="premium" className="premium-img" />
        </Carousel>
      </div>
    </section>
  );
}

export default PremiumBusiness;
