import React from "react";
import Banner from "../banner/banner";
import Header from "../header/header";
import PremiumBusiness from "../premium/premium";
import { Row, Col, Container, Button } from "react-bootstrap";
import restaurent from "../../assets/restaurents/meetinghall.png";
import Footer from "../footer/footer";
import "./category.css";

function Category() {
  return (
    <section id="category">
      <div>
        <Header />
        <div className="bg2">
          <Banner />
          <PremiumBusiness />

          {/* restaurant */}
          <div className="restaurant-head">Restaurants</div>
        </div>
        <div>
          <Container>
            <Row>
              <Col lg={4} className="res-col pb-5">
                <div className="res-div">
                  <img
                    src={require("../../assets/restaurents/meetinghall.png")}
                    alt="restaurents"
                  />
                  <p>De Alfresco Restaurant</p>
                </div>
              </Col>
              <Col lg={4} className="res-col pb-5">
                <div className="res-div">
                  <img
                    src={require("../../assets/restaurents/meetinghall.png")}
                    alt="restaurents"
                  />
                  <p>De Alfresco Restaurant</p>
                </div>
              </Col>
              <Col lg={4} className="res-col pb-5">
                <div className="res-div">
                  <img
                    src={require("../../assets/restaurents/meetinghall.png")}
                    alt="restaurents"
                  />
                  <p>De Alfresco Restaurant</p>
                </div>
              </Col>
              <Col lg={4} className="res-col pb-5">
                <div className="res-div">
                  <img
                    src={require("../../assets/restaurents/meetinghall.png")}
                    alt="restaurents"
                  />
                  <p>De Alfresco Restaurant</p>
                </div>
              </Col>
              <Col lg={4} className="res-col pb-5">
                <div className="res-div">
                  <img
                    src={require("../../assets/restaurents/meetinghall.png")}
                    alt="restaurents"
                  />
                  <p>De Alfresco Restaurant</p>
                </div>
              </Col>
              <Col lg={4} className="res-col pb-5">
                <div className="res-div">
                  <img
                    src={require("../../assets/restaurents/meetinghall.png")}
                    alt="restaurents"
                  />
                  <p>De Alfresco Restaurant</p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    </section>
  );
}

export default Category;
